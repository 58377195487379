.sale {
  position: relative;
  display: inline-block;
  background: #f2a6c4;
  color: white;
  height: 2.5rem;
  width: 2.5rem;
  text-align: center;
  vertical-align: middle;
  line-height: 2.5rem;
  transform: rotate(-20deg);
  animation: beat 1s ease infinite alternate;
}
.sale:before,
.sale:after {
  content: "";
  position: absolute;
  background: inherit;
  height: inherit;
  width: inherit;
  top: 0;
  left: 0;
  z-index: -1;
  transform: rotate(30deg);
}
.sale:after {
  transform: rotate(60deg);
}
@keyframes beat {
  from {
    transform: rotate(-20deg) scale(1);
  }
  to {
    transform: rotate(-20deg) scale(1.1);
  }
}
