.image-gallery-image {
  max-height: 300px !important;
}

.image-gallery-thumbnail-image {
  max-height: 60px !important;
  max-width: 100% !important;
  width: unset !important;
}

ul.check {
  list-style: none;
}

ul.check li:before {
  content: "✓";
  padding-inline-end: 5px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 80px;
  width: 40px;
}
