body {
  margin: 0;
  font-family: "Heebo", "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.line-through {
  text-decoration: line-through;
  /* text-decoration-style: double; */
  text-decoration-thickness: 2px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 2em;
  margin-right: 12px;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}

.sitePage {
  padding: 10px;
}

ul.check {
  list-style: none;
}

ul.check li:before {
  content: "✓";
  padding-inline-end: 5px;
}