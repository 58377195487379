.iphone-pro {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.iphone-pro .overlap-wrapper-2 {
  background-color: #ffffff;
  /* height: 3326px; */
  height: 100%;
  overflow-x: hidden;
  width: 100%;
}

.iphone-pro .overlap-4 {
  /* height: 3326px; */
  height: 100%;
  left: -217px;
  position: relative;
  width: 824px;
}

.iphone-pro .frame {
  height: 944px;
  left: 217px;
  position: absolute;
  top: 0;
  width: 390px;
}

.iphone-pro .overlap-5 {
  height: 943px;
  position: relative;
}

.iphone-pro .iphone-13 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.iphone-pro .iphone-instance {
  width: 333px !important;
}

.iphone-pro .iphone-13-instance {
  left: 30px !important;
  width: 122px !important;
}

.iphone-pro .design-component-instance-node {
  left: 0 !important;
}

.iphone-pro .iphone-2 {
  left: 33px !important;
  width: 89px !important;
}

.iphone-pro .iphone-3 {
  width: 136px !important;
}

.iphone-pro .iphone-4 {
  width: 102px !important;
}

.iphone-pro .hold-on {
  color: #000000;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 30px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 753px;
  width: 338px;
}

.iphone-pro .span {
  font-weight: 700;
}

.iphone-pro .text-wrapper-3 {
  color: #000000;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
}

.iphone-pro .HOLD-ON-LOGO-WHITE {
  height: 57px;
  left: 176px;
  position: absolute;
  top: 687px;
  width: 199px;
}

.iphone-pro .frame-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 2528px;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  top: 798px;
  width: 824px;
}

.iphone-pro .frame-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 824px;
}

.iphone-pro .frame-3 {
  height: 2528px;
  position: relative;
  width: 670px;
}

.iphone-pro .overlap-6 {
  height: 2528px;
  position: relative;
}

.iphone-pro .rectangle {
  background-color: var(--red);
  height: 425px;
  left: 217px;
  position: absolute;
  top: 1096px;
  width: 390px;
}

.iphone-pro .mask-group {
  height: 466px;
  left: 214px;
  position: absolute;
  top: 634px;
  width: 393px;
}

.iphone-pro .KIWI {
  height: 412px;
  left: 109px;
  position: absolute;
  top: 31px;
  transform: rotate(-51.09deg);
  width: 275px;
}

.iphone-pro .overlap-group-2 {
  height: 705px;
  left: -218px;
  position: relative;
  top: -135px;
  width: 703px;
}

.iphone-pro .untitled-design {
  height: 494px;
  left: 98px;
  position: absolute;
  top: 106px;
  transform: rotate(51.09deg);
  width: 508px;
}

.iphone-pro .untitled-design-2 {
  height: 469px;
  left: 111px;
  position: absolute;
  top: 110px;
  transform: rotate(51.09deg);
  width: 490px;
}

.iphone-pro .component {
  height: 633px;
  left: 229px;
  position: absolute;
  top: 421px;
  width: 367px;
}

.iphone-pro .image {
  height: 286px;
  left: 99px;
  position: absolute;
  top: -17px;
  width: 184px;
}

.iphone-pro .RIGHT {
  height: 240px;
  left: 292px;
  position: absolute;
  top: 0;
  width: 75px;
}

.iphone-pro .icon-chevron-right {
  height: 23px;
  left: 31px;
  position: absolute;
  top: 116px;
  width: 14px;
}

.iphone-pro .view {
  height: 55px;
  left: 19px;
  position: absolute;
  top: 578px;
  width: 327px;
}

.iphone-pro .element {
  height: 55px;
  left: 133px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 59px;
}

.iphone-pro .element-2 {
  height: 55px;
  left: 200px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 60px;
}

.iphone-pro .element-3 {
  height: 55px;
  left: 267px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 60px;
}

.iphone-pro .element-4 {
  height: 55px;
  left: 66px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 60px;
}

.iphone-pro .element-5 {
  height: 55px;
  left: 249px;
  object-fit: cover;
  position: absolute;
  top: 445px;
  width: 60px;
}

.iphone-pro .element-6 {
  height: 55px;
  left: 183px;
  object-fit: cover;
  position: absolute;
  top: 445px;
  width: 59px;
}

.iphone-pro .view-wrapper {
  height: 128px;
  left: 8px;
  position: absolute;
  top: 388px;
  width: 348px;
}

.iphone-pro .view-2 {
  height: 128px;
  position: relative;
  width: 352px;
}

.iphone-pro .p {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 30px;
  width: 348px;
}

.iphone-pro .text-wrapper-4 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
}

.iphone-pro .text-wrapper-5 {
  font-weight: 600;
}

.iphone-pro .text-wrapper-6 {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 158px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 190px;
}

.iphone-pro .text-wrapper-7 {
  color: #ffffff;
  direction: rtl;
  font-family: "Open Sans", Helvetica;
  font-size: 36px;
  font-style: italic;
  font-weight: 700;
  left: 263px;
  letter-spacing: 1.62px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1125px;
  width: 289px;
}

.iphone-pro .rectangle-2 {
  background-color: var(--light-yellow);
  height: 1007px;
  left: 217px;
  position: absolute;
  top: 1521px;
  width: 390px;
}

.iphone-pro .frame-4 {
  align-items: flex-start;
  background-color: #ea5143;
  border-radius: 20.5px;
  display: flex;
  flex-direction: column;
  height: 31px;
  left: 420px;
  padding: 5px 7px;
  position: absolute;
  top: 1637px;
  width: 113px;
}

.iphone-pro .text-wrapper-8 {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  height: 18px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 96px;
}

.iphone-pro .div-2 {
  color: #ea5143;
  direction: rtl;
  font-family: "Open Sans", Helvetica;
  font-size: 36px;
  font-weight: 400;
  left: 420px;
  letter-spacing: 5.04px;
  line-height: 38.5px;
  position: absolute;
  text-align: left;
  top: 1550px;
  width: 250px;
}

.iphone-pro .text-wrapper-9 {
  font-style: italic;
  font-weight: 300;
}

.iphone-pro .text-wrapper-10 {
  font-style: italic;
  font-weight: 700;
}

.iphone-pro .rectangle-3 {
  background-color: var(--blue);
  height: 326px;
  left: 214px;
  position: absolute;
  top: 1885px;
  width: 393px;
}

.iphone-pro .hold-on-2 {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 237px;
  line-height: normal;
  position: absolute;
  top: 1915px;
  width: 344px;
}

.iphone-pro .text-wrapper-11 {
  font-weight: 600;
  letter-spacing: 0;
}

.iphone-pro .text-wrapper-12 {
  letter-spacing: 0;
}

.iphone-pro .text-wrapper-13 {
  letter-spacing: -0.24px;
}

.iphone-pro .frame-5 {
  align-items: center;
  display: flex;
  gap: 3px;
  height: 80px;
  justify-content: flex-end;
  left: 270px;
  position: absolute;
  top: 2025px;
  width: 317px;
}

.iphone-pro .element-7 {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 60px;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  width: 260.53px;
}

.iphone-pro .text-wrapper-14 {
  font-weight: 600;
  line-height: 16.4px;
}

.iphone-pro .text-wrapper-15 {
  line-height: 16.4px;
}

.iphone-pro .warranty {
  height: 80px;
  position: relative;
  width: 52.63px;
}

.iphone-pro .frame-6 {
  align-items: center;
  display: flex;
  gap: 3px;
  height: 80px;
  justify-content: flex-end;
  left: 237px;
  position: absolute;
  top: 2105px;
  width: 350px;
}

.iphone-pro .element-holdon {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 60px;
  line-height: 16px;
  position: relative;
  width: 293.42px;
}

.iphone-pro .text-wrapper-16 {
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16.4px;
}

.iphone-pro .text-wrapper-17 {
  letter-spacing: -0.48px;
  line-height: 16.4px;
}

.iphone-pro .text-wrapper-18 {
  letter-spacing: 0;
  line-height: 16.4px;
}

.iphone-pro .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  height: 145px;
  left: 237px;
  position: absolute;
  top: 2233px;
  width: 294px;
}

.iphone-pro .text {
  align-self: stretch;
  color: #000000;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.6px;
  position: relative;
  text-align: right;
}

.iphone-pro .text-wrapper-19 {
  color: #000000;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.6px;
}

.iphone-pro .symbol {
  height: 32px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 2232px;
  width: 32px;
}

.iphone-pro .flexcontainer-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  height: 153px;
  left: 237px;
  position: absolute;
  top: 2363px;
  width: 296px;
}

.iphone-pro .symbol-2 {
  height: 32px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 2384px;
  width: 32px;
}

.iphone-pro .frame-7 {
  height: 301px;
  left: 218px;
  overflow-x: scroll;
  position: absolute;
  top: 1190px;
  width: 389px;
}

.iphone-pro .frame-8 {
  height: 282px;
  left: 78px;
  position: absolute;
  top: -2px;
  width: 1924px;
}

.iphone-pro .frame-9 {
  height: 282px;
  left: 337px;
  position: absolute;
  top: 0;
  width: 276px;
}

.iphone-pro .overlap-group-3 {
  height: 145px;
  left: 0;
  position: absolute;
  top: 0;
  width: 276px;
}

.iphone-pro .image-2 {
  height: 30px;
  left: 160px;
  position: absolute;
  top: 0;
  width: 50px;
}

.iphone-pro .text-wrapper-20 {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 18.2px;
  position: absolute;
  top: 11px;
  width: 194px;
}

.iphone-pro .ellipse-5 {
  height: 66px;
  left: 210px;
  object-fit: cover;
  position: absolute;
  top: 13px;
  width: 66px;
}

.iphone-pro .text-wrapper-21 {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 8px;
  letter-spacing: 0;
  line-height: 18.2px;
  position: absolute;
  top: 265px;
  white-space: nowrap;
  width: 187px;
}

.iphone-pro .line {
  height: 1px;
  left: 150px;
  position: absolute;
  top: 259px;
  width: 44px;
}

.iphone-pro .frame-10 {
  height: 247px;
  left: 663px;
  position: absolute;
  top: 0;
  width: 276px;
}

.iphone-pro .text-wrapper-22 {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 8px;
  letter-spacing: 0;
  line-height: 18.2px;
  position: absolute;
  top: 231px;
  white-space: nowrap;
  width: 187px;
}

.iphone-pro .line-2 {
  height: 1px;
  left: 150px;
  position: absolute;
  top: 224px;
  width: 44px;
}

.iphone-pro .frame-11 {
  height: 173px;
  left: 989px;
  position: absolute;
  top: 0;
  width: 276px;
}

.iphone-pro .ellipse-6 {
  height: 66px;
  left: 210px;
  position: absolute;
  top: 13px;
  width: 66px;
}

.iphone-pro .text-wrapper-23 {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 8px;
  letter-spacing: 0;
  line-height: 18.2px;
  position: absolute;
  top: 157px;
  white-space: nowrap;
  width: 187px;
}

.iphone-pro .line-3 {
  height: 1px;
  left: 150px;
  position: absolute;
  top: 150px;
  width: 44px;
}

.iphone-pro .frame-12 {
  height: 242px;
  left: 1648px;
  position: absolute;
  top: 0;
  width: 276px;
}

.iphone-pro .text-wrapper-24 {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 8px;
  letter-spacing: 0;
  line-height: 18.2px;
  position: absolute;
  top: 226px;
  white-space: nowrap;
  width: 187px;
}

.iphone-pro .line-4 {
  height: 1px;
  left: 150px;
  position: absolute;
  top: 219px;
  width: 44px;
}

.iphone-pro .frame-13 {
  height: 173px;
  left: 1314px;
  position: absolute;
  top: 0;
  width: 284px;
}

.iphone-pro .overlap-7 {
  height: 145px;
  left: 0;
  position: absolute;
  top: 0;
  width: 284px;
}

.iphone-pro .female-profile {
  height: 82px;
  left: 202px;
  position: absolute;
  top: 6px;
  width: 82px;
}

.iphone-pro .frame-14 {
  height: 263px;
  left: 0;
  position: absolute;
  top: 0;
  width: 287px;
}

.iphone-pro .overlap-8 {
  height: 145px;
  left: 0;
  position: absolute;
  top: 0;
  width: 287px;
}

.iphone-pro .image-3 {
  height: 30px;
  left: 172px;
  position: absolute;
  top: 0;
  width: 50px;
}

.iphone-pro .text-wrapper-25 {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 18.2px;
  position: absolute;
  top: 11px;
  width: 206px;
}

.iphone-pro .ellipse-7 {
  height: 66px;
  left: 221px;
  position: absolute;
  top: 13px;
  width: 66px;
}

.iphone-pro .text-wrapper-26 {
  color: #ffffff;
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 19px;
  letter-spacing: 0;
  line-height: 18.2px;
  position: absolute;
  top: 246px;
  white-space: nowrap;
  width: 187px;
}

.iphone-pro .line-5 {
  height: 1px;
  left: 161px;
  position: absolute;
  top: 240px;
  width: 44px;
}

.iphone-pro .ellipse-8 {
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 5px;
  height: 10px;
  left: 204px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-9 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 530px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-10 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 856px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-11 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1182px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-12 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1506px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-13 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1841px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-14 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 218px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-15 {
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 5px;
  height: 10px;
  left: 544px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-16 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 870px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-17 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1196px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-18 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1520px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-19 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1855px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-20 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 232px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-21 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 558px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-22 {
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 5px;
  height: 10px;
  left: 884px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-23 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1210px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-24 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1534px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-25 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1869px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-26 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 246px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-27 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 572px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-28 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 898px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-29 {
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 5px;
  height: 10px;
  left: 1224px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-30 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1548px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-31 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1883px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-32 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 260px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-33 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 586px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-34 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 912px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-35 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1238px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-36 {
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 5px;
  height: 10px;
  left: 1562px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-37 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1897px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-38 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 274px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-39 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 600px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-40 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 926px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-41 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1252px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-42 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 5px;
  height: 10px;
  left: 1576px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .ellipse-43 {
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 5px;
  height: 10px;
  left: 1911px;
  position: absolute;
  top: 291px;
  width: 10px;
}

.iphone-pro .frame-15 {
  height: 561px;
  left: 24px;
  position: absolute;
  top: 1324px;
  width: 583px;
}

.iphone-pro .overlap-9 {
  height: 561px;
  position: relative;
}

.iphone-pro .element-8 {
  height: 481px;
  left: 0;
  position: absolute;
  top: 80px;
  width: 583px;
}

.iphone-pro .frame-16 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 204px;
  justify-content: flex-end;
  left: 148px;
  padding: 0px 0px 1.75px;
  position: absolute;
  top: 0;
  width: 120px;
}

.iphone-pro .element-9 {
  height: 219.86px;
  margin-bottom: -7.14px;
  margin-left: -9px;
  margin-right: -5.06px;
  margin-top: -10.86px;
  position: relative;
  width: 134.04px;
}

.iphone-pro .element-10 {
  height: 201.86px;
  margin-top: -200px;
  position: relative;
  width: 116.04px;
}

.iphone-pro .frame-17 {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-top: -2363px;
  padding: 0px 7.63e-6px;
  position: relative;
  width: 348.24px;
}

.iphone-pro .frame-18 {
  align-items: flex-start;
  background-color: #382a85;
  border-radius: 20.5px;
  display: flex;
  flex-direction: column;
  height: 30px;
  padding: 4.39px 9.09e-13px;
  position: relative;
  width: 118.12px;
}

.iphone-pro .text-wrapper-27 {
  color: var(--light-yellow);
  direction: rtl;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  height: 20.49px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 106.9px;
}
