.iphone {
  background-color: #ffffff;
  height: 844px;
  width: 390px;
}

.iphone .overlap {
  left: -237px;
  position: relative;
  width: 1299px;
}

.iphone .ellipse {
  border-radius: 649.5px;
  height: 1299px;
  left: 0;
  position: absolute;
  width: 1299px;
}

.iphone .text-wrapper {
  color: var(--blue);
  direction: rtl;
  font-family: "Open Sans", Helvetica;
  font-size: 37.1px;
  font-style: italic;
  font-weight: 300;
  left: 379px;
  letter-spacing: 1.67px;
  line-height: normal;
  position: absolute;
  text-align: left;
  width: 300px;
}

.iphone .div {
  direction: rtl;
  font-family: "Open Sans", Helvetica;
  font-size: 37.1px;
  font-style: italic;
  font-weight: 700;
  left: 256px;
  letter-spacing: 1.67px;
  line-height: normal;
  position: absolute;
  text-align: left;
  width: 140px;
}

.iphone .text-wrapper-2 {
  color: var(--blue);
  direction: rtl;
  font-family: "Open Sans", Helvetica;
  font-size: 37.1px;
  font-style: italic;
  font-weight: 700;
  left: 397px;
  letter-spacing: 1.67px;
  line-height: normal;
  position: absolute;
  text-align: left;
  width: 110px;
}

.iphone .group {
  position: absolute;
}

.iphone .img {
  position: absolute;
}

.iphone .group-2 {
  position: absolute;
}

.iphone .overlap-group-wrapper {
  position: absolute;
}

.iphone .overlap-group {
  position: relative;
}

.iphone .ellipse-2 {
  background-color: #00000080;
  filter: blur(13.73px);
  position: absolute;
}

.iphone .dscf {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
}

.iphone .overlap-wrapper {
  position: absolute;
}

.iphone .overlap-2 {
  position: relative;
}

.iphone .ellipse-3 {
  background-color: #00000080;
  filter: blur(13.73px);
  position: absolute;
}

.iphone .dscf-2 {
  object-fit: cover;
  position: absolute;
  top: 0;
}

.iphone .div-wrapper {
  position: absolute;
}

.iphone .overlap-3 {
  position: relative;
}

.iphone .ellipse-4 {
  background-color: #00000080;
  filter: blur(13.73px);
  position: absolute;
}

.iphone .dscf-3 {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
}

.iphone .thirteen-pro-2 {
  height: 1602px;
  top: 46px;
}

.iphone .thirteen-pro-3 {
  height: 1528px;
  top: 120px;
}

.iphone .thirteen-pro-1 {
  height: 1536px;
  top: 112px;
}

.iphone .overlap.thirteen-pro-2 .ellipse {
  background-color: var(--green);
  top: 303px;
}

.iphone .overlap.thirteen-pro-3 .ellipse {
  background-color: var(--light-yellow);
  top: 229px;
}

.iphone .overlap.thirteen-pro-1 .ellipse {
  background-color: var(--pink);
  top: 237px;
}

.iphone .overlap.thirteen-pro-2 .text-wrapper {
  top: 74px;
}

.iphone .overlap.thirteen-pro-3 .text-wrapper {
  top: 0;
}

.iphone .overlap.thirteen-pro-1 .text-wrapper {
  top: 8px;
}

.iphone .overlap.thirteen-pro-2 .div {
  color: var(--green);
  top: 118px;
}

.iphone .overlap.thirteen-pro-3 .div {
  color: var(--red);
  top: 44px;
}

.iphone .overlap.thirteen-pro-1 .div {
  color: #f4aaca;
  top: 52px;
}

.iphone .overlap.thirteen-pro-2 .text-wrapper-2 {
  top: 118px;
}

.iphone .overlap.thirteen-pro-3 .text-wrapper-2 {
  top: 44px;
}

.iphone .overlap.thirteen-pro-1 .text-wrapper-2 {
  top: 52px;
}

.iphone .overlap.thirteen-pro-2 .group {
  height: 109px;
  left: 367px;
  top: 322px;
  width: 109px;
}

.iphone .overlap.thirteen-pro-3 .group {
  height: 410px;
  left: 217px;
  top: 262px;
  width: 352px;
}

.iphone .overlap.thirteen-pro-1 .group {
  height: 109px;
  left: 368px;
  top: 256px;
  width: 109px;
}

.iphone .overlap.thirteen-pro-2 .img {
  height: 431px;
  left: 193px;
  top: 0;
  width: 289px;
}

.iphone .overlap.thirteen-pro-3 .img {
  height: 152px;
  left: 358px;
  top: 252px;
  width: 152px;
}

.iphone .overlap.thirteen-pro-1 .img {
  height: 125px;
  left: 359px;
  top: 248px;
  width: 125px;
}

.iphone .overlap.thirteen-pro-2 .group-2 {
  height: 100px;
  left: 371px;
  top: 326px;
  width: 100px;
}

.iphone .overlap.thirteen-pro-3 .group-2 {
  height: 100px;
  left: 371px;
  top: 252px;
  width: 100px;
}

.iphone .overlap.thirteen-pro-1 .group-2 {
  height: 452px;
  left: 294px;
  top: 0;
  width: 395px;
}

.iphone .overlap.thirteen-pro-2 .overlap-group-wrapper {
  height: 247px;
  left: 538px;
  top: 241px;
  width: 152px;
}

.iphone .overlap.thirteen-pro-3 .overlap-group-wrapper {
  height: 216px;
  left: 528px;
  top: 188px;
  width: 133px;
}

.iphone .overlap.thirteen-pro-1 .overlap-group-wrapper {
  height: 247px;
  left: 174px;
  top: 175px;
  width: 152px;
}

.iphone .overlap.thirteen-pro-2 .overlap-group {
  height: 247px;
}

.iphone .overlap.thirteen-pro-3 .overlap-group {
  height: 216px;
}

.iphone .overlap.thirteen-pro-1 .overlap-group {
  height: 247px;
}

.iphone .overlap.thirteen-pro-2 .ellipse-2 {
  border-radius: 45.4px/8.89px;
  height: 18px;
  left: 30px;
  top: 209px;
  width: 91px;
}

.iphone .overlap.thirteen-pro-3 .ellipse-2 {
  border-radius: 43.95px/8.62px;
  height: 17px;
  left: 25px;
  top: 191px;
  width: 88px;
}

.iphone .overlap.thirteen-pro-1 .ellipse-2 {
  border-radius: 45.4px/8.89px;
  height: 18px;
  left: 30px;
  top: 209px;
  width: 91px;
}

.iphone .overlap.thirteen-pro-2 .dscf {
  height: 247px;
  width: 152px;
}

.iphone .overlap.thirteen-pro-3 .dscf {
  height: 216px;
  width: 133px;
}

.iphone .overlap.thirteen-pro-1 .dscf {
  height: 247px;
  width: 152px;
}

.iphone .overlap.thirteen-pro-2 .overlap-wrapper {
  height: 216px;
  left: 184px;
  top: 262px;
  width: 133px;
}

.iphone .overlap.thirteen-pro-3 .overlap-wrapper {
  height: 217px;
  left: 171px;
  top: 204px;
  width: 169px;
}

.iphone .overlap.thirteen-pro-1 .overlap-wrapper {
  height: 418px;
  left: 292px;
  top: 85px;
  width: 258px;
}

.iphone .overlap.thirteen-pro-2 .overlap-2 {
  height: 216px;
}

.iphone .overlap.thirteen-pro-3 .overlap-2 {
  height: 217px;
  left: 26px;
  width: 144px;
}

.iphone .overlap.thirteen-pro-1 .overlap-2 {
  height: 418px;
}

.iphone .overlap.thirteen-pro-2 .ellipse-3 {
  border-radius: 43.95px/8.62px;
  height: 17px;
  left: 25px;
  top: 191px;
  width: 88px;
}

.iphone .overlap.thirteen-pro-3 .ellipse-3 {
  border-radius: 57.15px/9.35px;
  height: 19px;
  left: 0;
  top: 172px;
  width: 114px;
}

.iphone .overlap.thirteen-pro-1 .ellipse-3 {
  border-radius: 84.96px/16.66px;
  height: 33px;
  left: 48px;
  top: 370px;
  width: 170px;
}

.iphone .overlap.thirteen-pro-2 .dscf-2 {
  height: 216px;
  left: 0;
  width: 133px;
}

.iphone .overlap.thirteen-pro-3 .dscf-2 {
  height: 217px;
  left: 20px;
  width: 123px;
}

.iphone .overlap.thirteen-pro-1 .dscf-2 {
  height: 418px;
  left: 0;
  width: 258px;
}

.iphone .overlap.thirteen-pro-2 .div-wrapper {
  height: 376px;
  left: 287px;
  top: 204px;
  width: 294px;
}

.iphone .overlap.thirteen-pro-3 .div-wrapper {
  height: 466px;
  left: 289px;
  top: 45px;
  width: 287px;
}

.iphone .overlap.thirteen-pro-1 .div-wrapper {
  height: 210px;
  left: 525px;
  top: 221px;
  width: 164px;
}

.iphone .overlap.thirteen-pro-2 .overlap-3 {
  height: 376px;
}

.iphone .overlap.thirteen-pro-3 .overlap-3 {
  height: 466px;
}

.iphone .overlap.thirteen-pro-1 .overlap-3 {
  height: 210px;
}

.iphone .overlap.thirteen-pro-2 .ellipse-4 {
  border-radius: 99.13px/16.22px;
  height: 32px;
  left: 45px;
  top: 299px;
  width: 198px;
}

.iphone .overlap.thirteen-pro-3 .ellipse-4 {
  border-radius: 85.57px/16.75px;
  height: 34px;
  left: 57px;
  top: 394px;
  width: 171px;
}

.iphone .overlap.thirteen-pro-1 .ellipse-4 {
  border-radius: 55.38px/9.06px;
  height: 18px;
  left: 25px;
  top: 167px;
  width: 111px;
}

.iphone .overlap.thirteen-pro-2 .dscf-3 {
  height: 376px;
  width: 294px;
}

.iphone .overlap.thirteen-pro-3 .dscf-3 {
  height: 466px;
  width: 287px;
}

.iphone .overlap.thirteen-pro-1 .dscf-3 {
  height: 210px;
  width: 164px;
}
